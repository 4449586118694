import sportsConst from "./sportsConst";
export function kindText(g) {
//승무패,핸디오언 텍스트 설정
  switch (g.kind) {
    case sportsConst.GAME_KIND_1X2:
      g.kindText = '승무패'
      break;
    case sportsConst.GAME_KIND_HANDICAP:
      g.kindText = '핸디캡'
      break;
    case sportsConst.GAME_KIND_OVERUNDER:
      g.kindText = '오버언더'
      break;
  }
}

export function fix2(g) {
  //배당 소수점 두자리 설정 1.8 => 1.80
  g.homeTeamOdds = g.homeTeamOdds.toFixed(2);
  g.awayTeamOdds = g.awayTeamOdds.toFixed(2);
  if (g.kind === sportsConst.GAME_KIND_1X2 && g.drawOdds > 0) {
    g.drawOdds = g.drawOdds.toFixed(2);
  }
}
