<template>

  <!-- 모바일 푸터 메뉴 -->
  <div class="mobile_foot_menu_sports_end_game">
    <ul class="foot_menu">
      <li @click="displayPostionChanged(sportsConst.GAME_DISPLAYPOSITION_1x2)">
        <span :class="{'active': disp === sportsConst.GAME_DISPLAYPOSITION_1x2}">
           1x2
        </span>
      </li>
      <li @click="displayPostionChanged(sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER)">
        <span :class="{'active': disp === sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER}">
          핸디/오언
				</span>
      </li>
      <li @click="displayPostionChanged(sportsConst.GAME_DISPLAYPOSITION_SPECIAL)">
        <span :class="{'active': disp === sportsConst.GAME_DISPLAYPOSITION_SPECIAL}">
          스페셜
				</span>
      </li>
      <li @click="displayPostionChanged(sportsConst.GAME_DISPLAYPOSITION_LIVE)">
        <span :class="{'active': disp === sportsConst.GAME_DISPLAYPOSITION_LIVE}">
          라이브
				</span>
      </li>
    </ul>
  </div>
</template>

<script>
  import sportsConst from "../../common/sportsConst";

  export default {
    name: "MobileSportsEndGamesFootMenuComp",
    data() {
      return {
        sportsConst,
        disp: sportsConst.GAME_DISPLAYPOSITION_1x2
      }
    },
    methods: {
      displayPostionChanged(disp) {
        //SportsGameResult.vue->$on
        this.disp = disp
        this.$bus.$emit('sportsEndGameDispChanged', disp)
      }
    }
  }
</script>

<style scoped>

  .mobile_foot_menu_sports_end_game {
    bottom: 0px;
    left: 0px;
    position: fixed;
    width: 100%;
    z-index: 100;
    border-radius: 10px;
    background-color: #2d2e35;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 20px, rgb(46, 46, 53) 0px 6px 30px inset;
  }

  .mobile_foot_menu_sports_end_game .foot_menu {
    height: 45px;
    margin: 10px 0px;
  }

  .mobile_foot_menu_sports_end_game .foot_menu li {
    float: left;
    width: 25%;
    text-align: center;
    height: 100%;
    cursor: pointer;
  }

  .mobile_foot_menu_sports_end_game .foot_menu li span {
    line-height: 45px;
    font-size: 14px
  }

  .mobile_foot_menu_sports_end_game .active {
    color: #f19f09;
  }
  @media screen and (max-width: 800px) {
    .mobile_foot_menu_sports_end_game {
      display: block !important;
    }
  }


</style>