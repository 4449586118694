<template>
    <div class="sports_links" style="margin-top: 10px;">
        <router-link class="bw2" tag="button"  :to="{path:'/sports_result'}">스포츠 결과</router-link>
        <router-link class="bw2" tag="button"  :to="{path:'/leisure_result'}">미니게임 결과</router-link>
    </div>
</template>

<script>
    export default {
        name: "GameResultLinks"
    }
</script>

<style scoped>

</style>