<template>
  <div>
    <div style="position:relative; top:0; left:0;">
      <div class="other_menu_list	box_type01">
        <!-- 메뉴 리스트	-->
        <ul class="mune_list01">
          <li>
            <router-link :to="{path:'/leisure/bet365_premiership'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> BET365 가상축구 프리미엄
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/leisure/bet365_superleague'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> BET365 가상축구 슈퍼리그
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/leisure/bet365_eurocup'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> BET365 가상축구 유로컵
              </div>
            </router-link>
          </li>
          <!--<li>
            <router-link :to="{path:'/leisure/bet365_worldcup'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> BET365 가상축구 월드컵
              </div>
            </router-link>
          </li>-->
          <li>
            <router-link :to="{path:'/leisure/bet365_goldenhill'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> BET365 가상경주 골든힐
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/leisure/bet365_hillside'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> BET365 가상경주 힐사이드
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="other_menu_list	box_type01">
        <!-- 메뉴 리스트	-->
        <ul class="mune_list01">
          <li>
            <router-link :to="{path:'/leisure/powerball'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> 파워볼
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/leisure/powerball_lotto'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> 파워볼숫자
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/leisure/powersadali'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> 파워 사다리
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/leisure/speedkino'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> 스피드 키노
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/leisure/kinosadali'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> 키노 사다리
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/leisure/nameddalidali'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> 네임드 다리다리
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/leisure/namedsadali'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> 네임드 사다리
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/leisure/namedspeedsadali'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> Speed 사다리
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/leisure/namedsnail'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> 네임드 달팽이
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/leisure/fx1m'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> FX 1M
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/leisure/fx2m'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> FX 2M
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/leisure/fx3m'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> FX 3M
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/leisure/fx4m'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> FX 4M
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/leisure/fx5m'}">
              <div class="menu01">
                <i class="fa fa-bars" aria-hidden="true"></i> FX 5M
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import leisureConst from "../../common/leisureConst";

  export default {
    name: "LeisureLeftComp",
    data() {
      return {
        leisureConst
      }
    }
  }
</script>

<style scoped>

</style>